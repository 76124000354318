import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Steps from "../../components/common/Steps";
import Information from "../../components/checkout/tabs/Information";
import Payment from "../../components/checkout/tabs/Payment";
import { useAppContext } from "../../context/useAppContext";
import { useNavigate, useParams } from "react-router-dom";
import { useRequest } from "../../hooks/useRequest";
import IconWithTextList from "../../components/common/IconWithTextList";
import PaymentDraft from "../../components/checkout/tabs/PaymentDraft";

const CheckoutStyle = styled.div`
  padding: 70px 0;
  .page__title {
    color: #303030;
    text-align: center;
    font-size: 42px;
    font-weight: 700;
    line-height: 51px;
    margin-bottom: 27px;
  }
  .icon_with_text {
    margin-top: 140px;
    padding: 60px 0 0;
    border-top: 1px solid rgba(48, 48, 48, 0.25);
    width: 100vw;
  }
  @media (max-width: 768px) {
    padding: 40px 0;
    .page__title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 15px;
    }
  }
`;

const tabsList = [
  {
    id: 2,
    title: "Description",
    component: ({ createOrderData, orderSummaryData, draftId }) => (
      <PaymentDraft
        createOrderData={createOrderData}
        draftId={draftId}
        orderSummaryData={orderSummaryData}
      />
    ),
  },
];

const CheckoutDraft = () => {
  const {draftId} = useParams();
  const [activeStep, setActiveStep] = useState(2);
  const { checkoutCartData, appliedCoupon } = useAppContext();
  const navigate = useNavigate();
  const [orderSummaryData, setOrderSummaryData] = useState([]);

  const [fetchOrderSummary] = useRequest("/order/checkout/summary");

  const checkoutCartId = checkoutCartData?._id;
  const [checkoutAddress, setCheckoutAddress] = useState({
    billing: "",
    shipping: "",
  });
  
  const [createOrderData, setCreateOrderData] = useState();
  const [billingType, setBillingType] = useState("deliver");

  useEffect(() => {
    if (!draftId) return navigate("/cart");
    async function getOrderSummary(draftId) {
      const path = `/order-draft/${draftId}/show-ungroup`;
      const response = await fetchOrderSummary({
        path: path,
        method: "GET",
      });
      if (response.success) {
        setOrderSummaryData(response?.data);
      }
    }
    getOrderSummary(draftId);
  }, [draftId]);

  const handleChangeBilling = useCallback((e, address, addressType, method) => {
    setCheckoutAddress((prev) => ({ ...prev, [method]: address }));
  }, []);

  const handleOrderNow = () => {
    setActiveStep(activeStep + 1);
  };

  const handleStepClick = (step) => {
    if (step?.id > activeStep) {
      return;
    }
    setActiveStep(step?.id);
  };

  return (
    <>
      <CheckoutStyle>
        <div className="container">
          <div className="page__title">Checkout</div>
          <div className="checkout__steps">
            <Steps activeIndex={activeStep} handleStepClick={() => {}} />
          </div>
          {tabsList
            ?.find((item) => item?.id === activeStep)
            ?.component({
              handleChangeBilling,
              draftId,
              handleOrderNow,
              orderSummaryData,
              checkoutAddress,
              createOrderData,
              billingType,
              setBillingType,
            })}
        </div>
      </CheckoutStyle>
      {activeStep === 2 && <IconWithTextList />}
    </>
  );
};

export default CheckoutDraft;
