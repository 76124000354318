import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ProductList from "../../components/product/ProductList";
import { useAppContext } from "../../context/useAppContext";
import { useRequest } from "../../hooks/useRequest";
import CategoryList from "../../components/category/CategoryList";
import SlidingBanner from "../../components/common/SlidingBanner";
import { Skeleton } from "@mui/material";
import Image from "../../components/Image/Image";
import { staticUrl } from "../../utils/baseUrl";
import { FaInstagram, FaFacebook, FaCode, FaYoutube, FaTwitter } from 'react-icons/fa'

const VendorStyle = styled.div`
.main_container{
position:relative;
padding-top:130px;
}

.pickup_container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 30px;
}
.row {
    display: flex;
    height:40px;
}

.row_column_1 {
    font-weight: 600;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #eeeeee;
    justify-content: flex-start;
    color: #ae0000;
    text-transform: capitalize;
    padding-inline: 20px;
    min-width: 133px;
}

.row_column_2{
    height: 100%;
    min-width: 300px;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    padding-left: 22px;
    border: 1px solid #eeeeee;
    text-transform:capitalize;
    }

  .vendor__logo {
    width: 185px;
    height: 185px;
    transform: translateY(-50%);
    border-radius: 14px;
    border: 0.75px solid #969696;
    background: #fff;
    overflow: clip;
    position:absolute;
    top:2px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .social_icon_container{
    display: flex;
    align-items: center;
    column-gap: 10px;
    position:absolute;
    top:20px;
    right:0;
  }

  .social_icon_a{    
    background: #ae0000;
    width: 30px;
    display: block;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    }

  .vendor__cover {
    height: 350px;
    img {
      object-fit: cover;
    }
  }
  .seller__categories {
    padding: 0 0 80px;
    .list_wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 40px;
    }
  }
  .products__list {
    padding: 60px 0;
  }

  .grid_heading{
    color: #303030;
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 34.5px;
  }

  .grid{
    display:grid;
    grid-template-columns:repeat(3,1fr);
    gap:20px;
    padding:50px 0;
  }
  .grid_card{
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #ae0000;
    grid-column: span 1 ;
  }
  .grid_card h5{
    margin-bottom: 14px;
    font-size: 15px;
    font-weight: 600;
    color: #ae0000;

  }
  .grid_card p{
  }
  @media (max-width: 768px) {


    .grid{
    grid-template-columns:repeat(1,1fr);
}
  .main_container{
position:relative;
padding-top:70px;
}

  .social_icon_container{
    position:unset;
    margin-bottom:20px;
  }


    .vendor__cover {
      margin-top: 0;
      height: 150px;
      img {
        object-fit: cover;
      }
    }
    .vendor__logo {
      width: 80px;
      height: 80px;
    }
    .seller__categories {
      .list_wrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
      }
    }
    .products__list {
      padding: 30px 0;
      .product__list__wrapper {
        padding-left: 0;
      }
    }
  }
`;

const Vendor = () => {
  const { sellerId } = useParams();
  const { isDesktop } = useAppContext();
  const [fetchSellerDetails, { isLoading: isFetchingSellerDetails }] =
    useRequest();
  const [sellerData, setSellerData] = useState();
  const { shopLogo, vendorName, topBanner, sliderBannersUrl, shopAddress, shopPhone, pickupAddress, pickupPoint } =
    sellerData || {};
  const [
    fetchCategories,
    { isLoading: isFetchingCategories, state: category },
  ] = useRequest(`/seller/${sellerId}/categories`);

  useEffect(() => {
    const fetchSellerInfo = async (sellerId) => {
      const paths = [
        `/seller/${sellerId}/show`,
        `/seller/${sellerId}/products`,
      ];
      const results = await Promise.all(
        paths?.map((path) => fetchSellerDetails({ path }))
      );
      console.log(results[0]?.data)
      setSellerData(results[0]?.data);
    };
    fetchSellerInfo(sellerId);
  }, [sellerId]);

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <VendorStyle>
      <div className="vendor__cover">
        {isFetchingSellerDetails ? (
          <Skeleton width={"100%"} height={400} variant="rectangular" />
        ) : (
          <Image
            className="w-100"
            src={staticUrl + "/" + topBanner}
            alt={vendorName}
          />
        )}
      </div>
      <div className="container main_container">
        <div className="social_icon_container">
          {sellerData?.socialMediaLinks?.instagram && <a target='_blank' href={sellerData?.socialMediaLinks?.instagram} className="social_icon_a">
            <FaInstagram size={15} />
          </a>}
          {sellerData?.socialMediaLinks?.facebook && <a target='_blank' href={sellerData?.socialMediaLinks?.facebook} className="social_icon_a">
            <FaFacebook size={15} />
          </a>}
          {sellerData?.socialMediaLinks?.twitter && <a target='_blank' href={sellerData?.socialMediaLinks?.twitter} className="social_icon_a">
            <FaTwitter size={15} />
          </a>}
          {sellerData?.socialMediaLinks?.youtube && <a target='_blank' href={sellerData?.socialMediaLinks?.youtube} className="social_icon_a">
            <FaYoutube size={15} />
          </a>}
          {/* {sellerData?.socialMediaLinks?.website && <a target='_blank' href={sellerData?.socialMediaLinks?.website} className="social_icon_a">
            <FaCode size={15} />
          </a>} */}
        </div>
        <div className="vendor__logo">
          <Image
            className="w-100"
            src={staticUrl + "/" + shopLogo}
            alt={vendorName}
          />
        </div>

        <div>
          <div className="title_wrapper">
            <h2 className="title grid_heading">Pickup Timmings</h2>
          </div>
          <div className="pickup_container">
            {sellerData?.pickupAddress?.availableHours?.map(element => (
              <div className="row">
                <div className="row_column_1">{element.day}</div>
                <div className="row_column_2">{element.value ?? '--'}</div>
              </div>
            ))}

          </div>
        </div>

        <div className="seller__categories">
          <CategoryList
            title={"Shop by Categories"}
            list={category?.data}
            loading={isFetchingCategories}
            type={"text-in-image"}
          />
        </div>
        <div>
          <div className="title_wrapper">
            <h2 className="title grid_heading">Seller Details</h2>
          </div>
          <div className="grid">
            <div className='grid_card'>
              <h5>Shop Address</h5>
              <p>
                {shopAddress}
              </p>
            </div>
            <div className='grid_card'>
              <h5>Shop Phone</h5>
              <p>
                {shopPhone}
              </p>
            </div>
            <div className='grid_card'>
              <h5>Pickup Address</h5>
              <p>
                {pickupAddress?.address}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        {sliderBannersUrl?.length > 0 && (
          <SlidingBanner
            bannerData={sliderBannersUrl}
            leftdistance={isDesktop ? 108 : 30}
            loading={isFetchingSellerDetails}
          />
        )}
      </div>
      <div className="container products__list">
        <ProductList apiPath={`/seller/${sellerId}/products`} />
      </div>
    </VendorStyle>
  );
};

export default Vendor;
